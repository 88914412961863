import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    paddingTop: 100,
    [theme.breakpoints.down("sm")]: {},
  },
  title: {
    marginBottom: "10px",
    textShadow: "0px 15px 30px #000000",
    text: "uppercase",
    fontWeight: "400",
  },
  content: {
    maxWidth: "500px",
    textAlign: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#F26522",
    fontSize: "24px",
    fontWeight: "700",
    textShadow: "0px 15px 30px #000000",
  },
}))

export const ProductionsDetailsHero = ({ project }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box>
        <Img
          style={{
            objectFit: "cover",
            height: "80vh",
            filter: "brightness(0.7)",
          }}
          fluid={project.ProjectThumbnail.imageFile.childImageSharp.fluid}
        />
        <Box
          position="absolute"
          my={5}
          px={2}
          top={0}
          left={0}
          height="100%"
          width="100%"
          overflow="hidden"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            maxWidth="820px"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h1" align="center" className={classes.title}>
              {project.ProjectTitle}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
