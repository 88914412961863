import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import ReactMarkdown from "react-markdown"

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "50px",
    maxWidth: "1000px",
    margin: "auto",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "90px",
    textAlign: "center",
  },
  title: {
    marginBottom: "20px",
    marginTop: "40px",
    textShadow: "0px 15px 30px #000000",
    text: "uppercase",
    fontWeight: "700",
  },
  content: {
    marginBottom: "20px",
    display: "block",
    "&::first-line": {
      color: "white",
      fontSize: "22px",
    },
  },
  link: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#D5810F",
    fontSize: "16px",
    fontWeight: "700",
    "&:hover": {
      color: "#FFF",
    },
  },
  back: {
    textAlign: "left",
    marginBottom: "20px",
  },
}))

export const ProductionsDetailsAbout = ({ project }) => {
  const classes = useStyles()
  console.log("project", project)

  return (
    <Box className={classes.container}>
      <Typography variant="caption" className={classes.content}>
        {project.ProjectDescription}
        {/* <ReactMarkdown source={project.ProjectDescription} /> */}
      </Typography>
    </Box>
  )
}
