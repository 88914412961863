import { graphql } from "gatsby"
import { Box } from "@material-ui/core"
import React from "react"
import LayoutProductions from "../components/layout/layout-productions"
import { ProductionsDetailsHero } from "../components/productions/productions-details-hero"
import { ProductionsDetailsAbout } from "../components/productions/productions-details-about"
import { ProductionsDetailsGallery } from "../components/productions/productions-details-gallery"
import { ProductionsDetailsProjects } from "../components/productions/productions-details-projects"
import Fade from "react-reveal/Fade"

export default function ProjectPage(props) {
  const { data } = props
  const project = data.strapi.project
  const projects = data.strapi.projects

  return (
    <LayoutProductions>
      <Fade>
        <ProductionsDetailsHero project={project} />
        <Box display="flex" alignItems="center" flexDirection="column">
          <ProductionsDetailsAbout project={project} />
          {/* <ProductionsDetailsGallery project={project} /> */}
        </Box>
        {/* <ProductionsDetailsProjects projects={projects} /> */}
      </Fade>
    </LayoutProductions>
  )
}

export const query = graphql`
  query ArticlePageQuery($id: ID!) {
    strapi {
      project(id: $id) {
        id
        ProjectTitle
        ProjectDescription
        ProjectThumbnail {
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ProjectImages {
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      projects {
        id
        ProjectTitle
        ProjectThumbnail {
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
